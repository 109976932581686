import qs from 'qs';
import { STRAPI_URL } from '@pickles/shared/config/env';

export const getDownloadAppBanner = async () => {
  const query = qs.stringify(
    {
      populate: {
        showcaseImage: {
          populate: '*',
        },
        backgroundImage: {
          populate: '*',
        },
        applinks: {
          populate: '*',
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const res = await fetch(`${STRAPI_URL}/api/download-app-banner?${query}`);
  const data = await res.json();
  return data;
};