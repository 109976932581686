export const enum AccountType {
  Dealer = 'DEALER',
  Broker = 'BROKER',
  Public = 'PUBLIC',
}

export const logoutErrors = ['AUTH_ERROR', 'TOKEN_EXPIRED_ERROR'];

export const allowedSendingBidDuration = 3000; //in milliseconds

export enum MyPurchaseType {
  unpaid = 'unpaid',
  paid = 'paid',
  offer = 'offer',
}

export const myPurchasesTabMenu = [
  { name: MyPurchaseType.unpaid, label: 'labels:unpaid', icon: 'unpaid', iconSize: 16 },
  { name: MyPurchaseType.offer, label: 'labels:offers', icon: 'offer', iconSize: 16 },
  { name: MyPurchaseType.paid, label: 'labels:paid', icon: 'paid', iconSize: 16 },
];

export const allowedNumberOfItemsPerSubmission = 5;

export const allowedPaymentReferenceLength = 50;
