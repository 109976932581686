import { IAuction, IBidding, IEbid, IGeneral } from '..';
import { Vehicle as ItemVehicle } from './item.vehicle.types';
import * as Flat from './item.flat.types';
import * as Vehicle from './item.vehicle.types';

export { Flat, Vehicle };

export interface Item {
  saleEvent?: SaleEvent;
  yard: {
    city?: string;
    location: string | null; // yardLoc
  };
  consignor: {
    name: string; // consignorName
    type?: ConsignType; // consignType
  };
  prebid?: Prebid;
  favorite?: Favorite;
  offer?: Offer;
  purchase?: Purchase;
  purchaseV2?: PurchaseV2;
  bidAmount?: number; // bid amount for display purpose

  // existing
  id: number;
  eventType: IAuction.AuctionTypes;
  itemType: Type;
  conditionType?: ConditionType;
  eventCategory: IGeneral.EventCategory;
  depositAmt: number;
  auctionRemarks?: string;
  inspectionRemarks?: string;

  documents?: Document[];
  image: string | null;
  images: string[];

  // itemID from /favorites endpoint
  itemId?: number;
  itemUpdated: string; // date
  minStartingBidAmt?: number;
  name: string;
  notes?: string;
  saleItemUpdated: string | null;
  saleItemCreated: string | null;
  sourceType: string;
  status?: Status;
  stockNum: string;
  viewsCount?: number;
  vehicle?: ItemVehicle;
  bidding?: IBidding.Bidding;
  isEbid?: boolean;
  isLive?: boolean;
  isHold?: boolean;
}

export interface SaleEvent {
  // item is lotted
  id: number; // saleEventId
  name: string; // saleEventName
  type?: IAuction.AuctionTypes; // saleEventType
  endAt?: string;
  startAt?: string;
  lotNum?: string;
}

export interface Favorite {
  favorite: boolean;
  itemId: number;
}

export interface Prebid {
  // prebid are label as maxbid
  amount: number;
  id: number;
  itemId: number;
}
export interface Offer {
  badgeNo: number;
  offerAmount: number;
  offerAt: string;
  saleEventId: number | null;
  saleStatus: SaleStatus;
}
export interface Purchase {
  dueAmt: number | null;
  moc: null | string;
  payBy: string;
  showDueAmt: boolean;
  soldAmt: number;
  soldAt: string;
  towBy: null | string;
  needPayment: boolean;
}

export interface PurchaseV2 {
  soldAmt: number;
  soldAt: string;
  isItemBalancePayableToConsignor: boolean;
  invoices: ItemPurchaseInvoice[];
  paymentStatus: PaymentStatusType;
  amountPayableToPickles: number;
  amountPayableToConsignor: number;
  paymentTerms?: IGeneral.PaymentTerms;
  payBy: string;
  finalizedAt: string | null;
  totalPaidAmount: number;
  totalPendingApprovalAmount: number;
  paymentSubmissions?: ItemPaymentSubmission[];
}

// FIXME: Remaining usage in mobile
export interface EbiddingActivityItem {
  [key: number]: Ebid | undefined;
  auctionStatus: IEbid.AuctionStatus;
  bidCount: number;
  clientItemId: string;
  currentBidAmt?: number;
  description?: string;
  endTime: number;
  eventId: number;
  eventName: string;
  imgUrl: string;
  lastBid: number;
  lotNum: string;
  minBalanceReq: number;
  minStartingBid: number;
  name: string;
  saleMessage?: string;
  saleStatus?: 'OFFER';
  startTime: number;
  stockNum: null | string;
  winnerId: string;
}

// FIXME: Remaining usage in mobile
export interface Ebid {
  currentBid: number | undefined;
  msgType: any;
  nextBid: number;
  id: number;
  currentBidAmt?: number;
  autobidActive?: boolean;
  maxAutobid?: number;
  lastBid: number;
  nextBidAmt: number;
  winnerId?: string;
  auctionStatus: IEbid.AuctionStatus;
  startTime: number;
  endTime: number;
}

/**
 * Item consign types
 */
export enum CONSIGN_TYPE {
  CORP = 'CORP',
  FLEET = 'FLEET',
  GOV = 'GOV',
  INSURANCE_COMP = 'INSURANCE_COMP',
  MIL = 'MIL',
  OTHER = 'OTHER',
  PRIV = 'PRIV',
  REPO = 'REPO',
  RESTORED = 'RESTORED',
}

export const ItemConsignTypesMap = {
  [CONSIGN_TYPE.CORP]: 'Corporation',
  [CONSIGN_TYPE.FLEET]: 'Fleet',
  [CONSIGN_TYPE.GOV]: 'Government',
  [CONSIGN_TYPE.INSURANCE_COMP]: 'Insurance Company',
  [CONSIGN_TYPE.MIL]: 'Military',
  [CONSIGN_TYPE.OTHER]: 'Other Owner',
  [CONSIGN_TYPE.PRIV]: 'Private',
  [CONSIGN_TYPE.REPO]: 'Repossession (Bank)',
  [CONSIGN_TYPE.RESTORED]: 'Repairer',
};

export type ConsignType = keyof typeof CONSIGN_TYPE;

/**
 * Item types
 */
export enum TYPE {
  EQUIP = 'EQUIP',
  HALFCUT = 'HALFCUT',
  MOTORCYCLE = 'MOTORCYCLE',
  OTHER = 'OTHER',
  PARTS = 'PARTS',
  VEHICLE = 'VEHICLE',
}

export const ItemTypesMap = {
  [TYPE.EQUIP]: 'Equipment/Industrial',
  [TYPE.HALFCUT]: 'Half-cut',
  [TYPE.MOTORCYCLE]: 'Motorcycle',
  [TYPE.OTHER]: 'Other',
  [TYPE.PARTS]: 'Parts',
  [TYPE.VEHICLE]: 'Car',
};
export type Type = keyof typeof TYPE;

/**
 * Item statuses
 */
export enum STATUS {
  AVAILABLE = 'AVAILABLE',
  INVHOLD = 'INVHOLD',
  LOTTED = 'LOTTED',
  PENDINSPECT = 'PENDINSPECT',
  PREINTAKE = 'PREINTAKE',
  PRESALEPEND = 'PRESALEPEND',
  RECORDED = 'RECORDED',
  REDEEMED = 'REDEEMED',
  RETURNED = 'RETURNED',
  SALEPEND = 'SALEPEND',
  SOLD = 'SOLD',
  STRPAID = 'STRPAID',
  STRPEND = 'STRPEND',
  STRUNPAID = 'STRUNPAID',
  TOWING = 'TOWING',
}
export const ItemStatusesMap = {
  [STATUS.AVAILABLE]: 'Available',
  [STATUS.INVHOLD]: 'Hold',
  [STATUS.LOTTED]: 'Lotted',
  [STATUS.PENDINSPECT]: 'Pending Inspection',
  [STATUS.PREINTAKE]: 'Pre-Intake',
  [STATUS.PRESALEPEND]: 'Pre-Sale Pending',
  [STATUS.RECORDED]: 'Recorded', // Not Inspected
  [STATUS.REDEEMED]: 'Redeemed',
  [STATUS.RETURNED]: 'Returned',
  [STATUS.SALEPEND]: 'Sale Pending',
  [STATUS.SOLD]: 'Sold',
  [STATUS.STRPAID]: 'Storage Fee Paid',
  [STATUS.STRPEND]: 'Storage Fee Pending',
  [STATUS.STRUNPAID]: 'Storage Fee Unpaid',
  [STATUS.TOWING]: 'Towing',
};
export type Status = keyof typeof STATUS;

/**
 * Item condition types
 */
enum CONDITION_TYPE {
  HALFCUT = 'HALFCUT',
  NEW = 'NEW',
  REPOSSESSED = 'REPOSSESSED',
  RESTORED = 'RESTORED',
  SCRAP = 'SCRAP',
  USED = 'USED',
  WRECK = 'WRECK',
}
export const ItemConditionTypesMap = {
  [CONDITION_TYPE.HALFCUT]: 'Half-cut',
  [CONDITION_TYPE.NEW]: 'New',
  [CONDITION_TYPE.REPOSSESSED]: 'Repossessed',
  [CONDITION_TYPE.RESTORED]: 'Restored',
  [CONDITION_TYPE.SCRAP]: 'Scrap',
  [CONDITION_TYPE.USED]: 'Used',
  [CONDITION_TYPE.WRECK]: 'Wreck',
};
export type ConditionType = keyof typeof CONDITION_TYPE;

/**
 * Item document
 */
export type Document = {
  contentType: string;
  description: string;
  location: string;
  name: string;
};

/**
 * Item sale status
 */
export enum SALE_STATUS {
  OFFER = 'OFFER',
  NOSALE = 'NOSALE',
  SOLD = 'SOLD',
}
export type SaleStatus = keyof typeof SALE_STATUS;

export interface Bidding {
  itemId: number;
  bidding: Partial<IBidding.Bidding>;
}

export enum PurchaseStatus {
  unpaid = 'unpaid',
  paid = 'paid',
}

export type PurchaseStatusType = keyof typeof PurchaseStatus;

export enum PaymentStatus {
  paid = 'paid',
  pendingApproval = 'pendingApproval',
  partialSubmitted = 'partialSubmitted',
  pendingPayment = 'pendingPayment',
}

export type PaymentStatusType = keyof typeof PaymentStatus;

export interface ItemPurchaseInvoice {
  invoiceId: number;
  itemId: number;
  invoiceNumber: string;
  invoiceType: IGeneral.InvoiceTypeType;
  invoiceAutoSettle: boolean;
  invoiceDate: string;
  invoiceAmount: number;
  invoicePaidAmount: number;
  invoicePendingApprovalAmount: number;
}

export enum PaymentSubmissionStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type PaymentSubmissionStatusType = keyof typeof PaymentSubmissionStatus;

export interface ItemPaymentSubmission {
  buyerPaymentId: number;
  paymentReference: string;
  appliedAmount: number;
  status: PaymentSubmissionStatusType;
  createdAt: string;
}

export interface PaymentItem {
  itemId: number;
  invoiceId: number;
  appliedAmount: number;
}

export interface PaymentItemRecord {
  buyerPaymentId: number;
  itemId: number;
  itemInvoiceId: number;
  invoiceNumber: string;
  appliedAmount: number;
}

export type AppliedPayment = {
  itemId: number;
  appliedAmount: number;
};

export type SelectedPayments = {
  [itemId: number]: AppliedPayment;
};

export type SubmitPaymentFields = {
  paymentMethod: IGeneral.PaymentMethodType;
  paymentReference: string;
  payments: SelectedPayments;
};
