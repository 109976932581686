import { call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import config from '../../config';
import { IApp, IAuction, IGeneral, IItem, IUser } from '../../models';
import { checkAuctionEnded, checkAuctionStarted } from '../../utils/helpers';
import { dashboardActions, ebidActions, itemActions } from '../../redux/actions';
import { ApplicationSelectors, ItemSelectors, UserSelectors } from '../../redux/selectors';
import { api } from '../../services/api';
import { ItemsRequestBody } from '../../services/api/request.types';
import {
  AppSearchResponse,
  ItemResponse,
  ItemsResponse,
  OffersResponse,
  PaymentDetailsResponse,
  PaymentSubmissionResponse,
  PurchasesResponse,
  PurchasesV2Response,
  RecommendationsResponse,
} from '../../services/api/response.types';
import {
  getFavorites,
  getFavoritesAll,
  getFavoritesIds,
  setFavorite,
  setFavoritesNote,
} from './favorites';
import { getPrebids } from './prebids';

// TODO: Should allow logged in user to retrieve all items even if its auction ended for SOLD/OFFER
export function* getItemsByIds(action: ReturnType<typeof itemActions.getItemsByIds>) {
  const params = action.payload;
  const response: ItemsResponse = yield call(api.getItems, params);
  if (response.ok && response.data && response.headers) {
    _logger.info('getItemsByIds', { response: response.data });
    yield put(itemActions.getItemsSuccess(response.data));
  } else {
    yield put(itemActions.getItemsFailure('get items error'));
  }
}

export function* getItems(action: ReturnType<typeof itemActions.getItems>) {
  const params = action.payload;
  const appConfig: IApp.MobileAppConfig = yield select(ApplicationSelectors.mobileAppConfig);
  const itemsListingPerpage = appConfig?.itemsListingPerpage;
  const { saleEventId, make, model, location } = params;
  _logger.info('saga/item.getItems', { saleEventId });
  const response: ItemsResponse = yield call(api.getItems, {
    ...params,
    perpage: itemsListingPerpage || config.ITEMS_LISTING_PERPAGE || 50,
  });

  const page = params.page || 1;

  if (response.ok && response.data && response.headers) {
    const itemTotal = response.headers['data-total-records'];

    if (saleEventId) {
      if (response.data.items) {
        yield put(dashboardActions.setAuctionPagination(saleEventId, page, +itemTotal));
      }

      // first time request does not contain make, model, location
      if (!make && !model && !location) {
        yield put(dashboardActions.setAvailableAuctionFilter(saleEventId, response.data.items));
      }

      yield put(dashboardActions.setAvailableAuctionItems(saleEventId, page, response.data.items));

      yield put(ebidActions.subscribeEventBid({ saleEventId }));
    }

    yield put(itemActions.getItemsSuccess(response.data));

    const account: IUser.Account = yield select(UserSelectors.account);
    if (account) {
      const ebidItemsIds = Object.values(response.data.items)
        .filter((item) => item.eventType === IAuction.AUCTION_TYPES.STATIC)
        .map((item) => item.id);
      if (ebidItemsIds.length > 0) {
        yield put(itemActions.getEbidItems(ebidItemsIds));
      }
    }
  } else {
    yield put(itemActions.getItemsFailure('get items error'));
  }
}

export function* getItem(action: ReturnType<typeof itemActions.getItem>) {
  const itemId = action.payload;

  const response: ItemResponse = yield call(api.getItem, itemId);

  const items: ReturnType<typeof ItemSelectors.items> = yield select(ItemSelectors.items);
  const now: number = yield select(ApplicationSelectors.timer);

  const item = items[itemId];
  const includeSniperTime = true;
  let isAuctionStarted = item && checkAuctionStarted(item);
  let isAuctionEnded = item && checkAuctionEnded(item, includeSniperTime);

  if (response.ok && response.data) {
    yield put(itemActions.getItemSuccess(itemId, response.data));
    _logger.info('saga/item.getItem', { itemId, response });

    const isEbidAuction = response.data.eventType === IAuction.AUCTION_TYPES.STATIC;

    // If Item Details page is refreshed, the state `item` might be empty at the time when this function is called, depending on the order of the saga execution of getItems and getItem.
    if (!item) {
      isAuctionStarted = now > moment(response.data.saleEventStartAt).valueOf();
      isAuctionEnded = now >= moment(response.data.saleEventEndAt).valueOf();
    }

    if (isEbidAuction && isAuctionStarted && !isAuctionEnded) {
      yield put(itemActions.getEbidItems([itemId]));
      yield put(ebidActions.subscribeItemBid({ itemId }));
      yield put(ebidActions.getBidHistory(itemId));
    } else if (isEbidAuction && isAuctionEnded) {
      yield put(itemActions.getEbidItems([itemId]));
    }
  } else {
    if (item?.isEbid && isAuctionEnded && response.status === 404) {
      yield put(itemActions.getEbidItems([itemId]));
    }

    _logger.info('saga/item.getItem failed', response);
    yield put(itemActions.getItemFailure(response.data));
  }
}

export function* getRecommendations(action: ReturnType<typeof itemActions.getRecommendationsList>) {
  const { page } = action.payload;

  const response: RecommendationsResponse = yield call(api.getRecommendations, { page });

  if (response.ok && response.data) {
    yield put(itemActions.getRecommendationsListSuccess(response.data));
  } else {
    yield put(itemActions.getRecommendationsListFailure('Recommendation List error'));
  }
}

export function* getPurchases(action: ReturnType<typeof itemActions.getPurchases>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    _logger.warn('saga/item.getPurchases without logged in?');
    return;
  }

  const { page } = action.payload;
  _logger.info('saga/item.getPurchases', action);
  const response: PurchasesResponse = yield call(api.getPurchases, { page });

  if (response.ok && response.data && response.headers) {
    const itemTotal = response.headers['data-total-records'];

    yield put(itemActions.getPurchasesSuccess(response.data));
    yield put(dashboardActions.setPurchasePagination(page, +itemTotal));
  } else {
    yield put(itemActions.getPurchasesFailure('getPurchases error'));
  }
}

const purchaseSelectors = {
  [IItem.PurchaseStatus.unpaid]: select(ItemSelectors.unpaidPurchases),
  [IItem.PurchaseStatus.paid]: select(ItemSelectors.paidPurchases),
};

export function* getPurchasesV2(action: ReturnType<typeof itemActions.getPurchasesV2>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    _logger.warn('saga/item.getPurchaseV2 without logged in?');
    return;
  }

  const { page, status } = action.payload;
  _logger.info('saga/item.getPurchasesV2', action);
  const response: PurchasesV2Response = yield call(api.getPurchasesV2, {
    status,
    page,
  });

  if (response.ok && response.data && response.headers) {
    const itemTotal = response.headers['data-total-records'];
    const pageSize = response.headers['data-page-size'];

    const isFirstPage = page === 1;
    const hasMoreThanOnePage = +itemTotal > +pageSize;

    if (isFirstPage) {
      yield put(dashboardActions.clearPurchaseV2Filter());
    }

    if (isFirstPage && hasMoreThanOnePage) {
      const purchases: IItem.Item[] = yield purchaseSelectors[status];
      if (purchases.length > 0) {
        yield put(itemActions.clearPurchasesV2(purchases));
      }
    }

    yield put(itemActions.getPurchasesV2Success(response.data));
    yield put(dashboardActions.setPurchaseV2Pagination(page, +itemTotal, status));
    yield put(dashboardActions.setAvailablePurchaseV2Filter(response.data));
  } else {
    yield put(itemActions.getPurchasesV2Failure('getPurchasesV2 error'));
  }
}

export function* getPaymentDetails(action: ReturnType<typeof itemActions.getPaymentDetails>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    _logger.warn('saga/item.getPaymentDetails without logged in?');
    return;
  }

  const { itemId } = action.payload;
  _logger.info('saga/item.getPaymentDetails', action);
  const response: PaymentDetailsResponse = yield call(api.getPaymentDetails, itemId);

  if (response.ok && response.data && response.headers) {
    yield put(itemActions.getPaymentDetailsSuccess(response.data));
  } else {
    yield put(itemActions.getPaymentDetailsFailure('getPaymentDetails error'));
  }
}

export function* submitPayment(action: ReturnType<typeof itemActions.submitPayment>) {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    _logger.warn('saga/item.submitPayment without logged in?');
    return;
  }

  const { fields, paymentProof } = action.payload;
  const unpaidPurchases: IItem.Item[] = yield select(ItemSelectors.unpaidPurchases);
  const unpaidPurchasesMap: { [itemId: number]: IItem.Item } = unpaidPurchases.reduce(
    (acc, item) => ({ ...acc, [item.id]: item }),
    {},
  );

  const mappedPaymentsToInvoice: IItem.PaymentItem[] = Object.values(fields.payments).flatMap(
    (payment) => {
      const invoices = unpaidPurchasesMap[payment.itemId]?.purchaseV2?.invoices;
      if (!invoices || invoices.length === 0) {
        return [];
      }

      let appliedAmount = payment.appliedAmount;
      let invoiceIndex = 0;
      const paymentItems: IItem.PaymentItem[] = [];

      while (appliedAmount > 0 && invoiceIndex < invoices.length) {
        const invoice = invoices[invoiceIndex];
        const remainingAmount =
          invoice.invoiceAmount - invoice.invoicePaidAmount - invoice.invoicePendingApprovalAmount;
        const amountToApply = Math.min(remainingAmount, appliedAmount);
        paymentItems.push({
          invoiceId: invoice.invoiceId,
          itemId: payment.itemId,
          appliedAmount: amountToApply,
        });

        appliedAmount -= amountToApply;
        invoiceIndex++;
      }

      return paymentItems;
    },
  );

  yield put(
    itemActions.createPayment({
      ...fields,
      payments: mappedPaymentsToInvoice,
    }),
  );

  const createPaymentRace: {
    success?: ReturnType<typeof itemActions.createPaymentSuccess>;
    error?: ReturnType<typeof itemActions.createPaymentFailure>;
  } = yield race({
    success: take(itemActions.createPaymentSuccess.type),
    error: take(itemActions.createPaymentFailure.type),
  });

  if (createPaymentRace.success) {
    const buyerPaymentId = createPaymentRace.success.payload;
    yield put(itemActions.uploadPaymentProof(buyerPaymentId, paymentProof));
  }

  if (createPaymentRace.error) {
    yield put(itemActions.submitPaymentFailure(createPaymentRace.error.payload));
    return;
  }

  yield put(itemActions.submitPaymentSuccess());
}

export function* createPayment(action: ReturnType<typeof itemActions.createPayment>) {
  _logger.info('saga/item.createPayment', action);
  const response: PaymentSubmissionResponse = yield call(api.submitPayment, action.payload);

  if (response.ok && response.data) {
    yield put(itemActions.createPaymentSuccess(response.data.buyerPaymentId));
  } else {
    yield put(itemActions.createPaymentFailure(response.data.code));
  }
}

export function* getOffers() {
  const account: IUser.Account = yield select(UserSelectors.account);
  if (!account) {
    _logger.warn('saga/item.getOffers without logged in?');
    return;
  }

  const response: OffersResponse = yield call(api.getOffers);

  if (response.ok && response.data) {
    yield put(itemActions.getOffersSuccess(response.data));
    yield put(dashboardActions.clearPurchaseV2Filter());
    yield put(dashboardActions.setAvailablePurchaseV2Filter(response.data));
  } else {
    yield put(itemActions.getOffersFailure('getOffers error'));
  }
}

export function* appSearch(action: ReturnType<typeof itemActions.getSearchResults>) {
  const { page = 1, search, make, model, year, condition, category } = action.payload;
  const appConfig: IApp.MobileAppConfig = yield select(ApplicationSelectors.mobileAppConfig);
  const pageSize = appConfig?.itemsListingPerpage || config.ITEMS_LISTING_PERPAGE || 50;

  const filterFields = ['year', 'make', 'model', 'condition_type', 'event_category'];
  const filterValues = [year, make, model, condition, category];

  let filters: any[] = [];
  filterFields.forEach((data, index) => {
    if (filterValues[index] && filterValues[index]?.length !== 0) {
      filters.push({ [data]: filterValues[index] });
    }
  });

  if (page === 1) {
    yield put(itemActions.clearSearchResults());
  }

  const response: AppSearchResponse = yield call(api.getAppSearchResult, {
    page: { current: page, size: pageSize },
    query: search,
    filters: { all: filters },
    sort: [{ _score: 'desc' }, { updated_at: 'desc' }],
  });

  if (response.ok && response.data) {
    const totalSearchResults = response.data.meta.page.totalResults;
    if (!make || !model || !year || !condition || !category) {
      yield put(dashboardActions.setAvailableSearchFilter(response.data.results));
    }

    yield put(itemActions.getSearchResultsSuccess(response.data.results));
    yield put(dashboardActions.setSearchPagination(search || '', page, totalSearchResults));
  } else {
    yield put(itemActions.getSearchResultsFailure('appSearchError'));
  }
}

const itemStatusesForRecentItems = [
  IItem.STATUS.LOTTED,
  IItem.STATUS.INVHOLD,
  IItem.STATUS.AVAILABLE,
];

function* getLatestVehicles() {
  const params: ItemsRequestBody = {
    eventCategory: IGeneral.EVENT_CATEGORY.USED_CARS,
    status: itemStatusesForRecentItems,
    perpage: 24,
  };

  const response: ItemsResponse = yield call(api.getItems, params);

  if (response.ok && response.data) {
    yield put(itemActions.getLatestVehiclesSuccess(response.data));
  } else {
    yield put(itemActions.getLatestVehiclesFailure('getLatestVehiclesFailure'));
  }
}

function* getRecentUsedMotorcycles() {
  const params: ItemsRequestBody = {
    // TODO: Update to used motorcycle category once it has been created
    eventCategory: IGeneral.EVENT_CATEGORY.USED_CARS,
    status: itemStatusesForRecentItems,
    perpage: 9,
  };

  const response: ItemsResponse = yield call(api.getItems, params);

  if (response.ok && response.data) {
    yield put(itemActions.getRecentUsedMotorcyclesSuccess(response.data));
  } else {
    yield put(itemActions.getRecentUsedMotorcyclesFailure('getRecentUsedMotorcyclesFailure'));
  }
}

function* getRecentIndustialItems() {
  const params: ItemsRequestBody = {
    eventCategory: IGeneral.EVENT_CATEGORY.INDUSTRIAL,
    status: itemStatusesForRecentItems,
    perpage: 9,
  };

  const response: ItemsResponse = yield call(api.getItems, params);

  if (response.ok && response.data) {
    yield put(itemActions.getRecentIndustrialItemsSuccess(response.data));
  } else {
    yield put(itemActions.getRecentIndustrialItemsFailure('getLatestVehiclesFailure'));
  }
}

function* getRecentSalvageItems() {
  const params: ItemsRequestBody = {
    eventCategory: IGeneral.EVENT_CATEGORY.SALVAGE,
    status: itemStatusesForRecentItems,
    perpage: 9,
  };

  const response: ItemsResponse = yield call(api.getItems, params);

  if (response.ok && response.data) {
    yield put(itemActions.getRecentSalvageItemsSuccess(response.data));
  } else {
    yield put(itemActions.getRecentSalvageItemsFailure('getRecentSalvageItemsFailure'));
  }
}

export default function* itemSaga() {
  yield takeLatest(itemActions.getItems.type, getItems);
  yield takeLatest(itemActions.getItemsByIds.type, getItemsByIds);
  yield takeLatest(dashboardActions.setSelectedAuctionFilter.type, getItems);
  yield takeEvery(itemActions.getItem.type, getItem);
  // Latest Vehicles
  yield takeLatest(itemActions.getLatestVehicles.type, getLatestVehicles);
  yield takeLatest(itemActions.getRecentUsedMotorcycles.type, getRecentUsedMotorcycles);
  yield takeLatest(itemActions.getRecentIndustrialItems.type, getRecentIndustialItems);
  yield takeLatest(itemActions.getRecentSalvageItems.type, getRecentSalvageItems);
  // Recommendations
  yield takeLatest(itemActions.getRecommendationsList.type, getRecommendations);
  // Purchases
  yield takeLatest(itemActions.getPurchases.type, getPurchases);
  yield takeLatest(itemActions.getPurchasesV2.type, getPurchasesV2);
  yield takeLatest(itemActions.getPaymentDetails.type, getPaymentDetails);
  // Payment
  yield takeLatest(itemActions.submitPayment.type, submitPayment);
  yield takeLatest(itemActions.createPayment.type, createPayment);
  // Offers
  yield takeLatest(itemActions.getOffers.type, getOffers);
  // Prebids
  yield takeLatest(itemActions.getPrebids.type, getPrebids);
  // Favorites
  yield takeLatest(itemActions.getFavoritesAll.type, getFavoritesAll);
  yield takeLatest(itemActions.setFavoritesNote.type, setFavoritesNote);
  yield takeLatest(itemActions.setFavorite.type, setFavorite);
  yield takeEvery(itemActions.getFavorites.type, getFavorites);
  yield takeLatest(itemActions.getFavoritesIds.type, getFavoritesIds);
  yield takeLatest(itemActions.getSearchResults.type, appSearch);
  yield takeLatest(dashboardActions.setSelectedSearchFilter.type, appSearch);
}
