// prettier-ignore
export const en = {
  buttons: {
    add_payment_receipt: 'Add Payment Receipt',
    add_towing_details: 'Add Towing Details',
    add_watchlist: 'Add to Watchlist',
    apply: 'Apply',
    attend: 'Attend',
    back_to_login: 'Back to Login',
    back_to_sign_in: 'Back to Sign In',
    back: 'Back',
    bid_no_sale: 'NO SALE',
    bid_now: 'Bid Now',
    buyer_registration: 'Buyer Registration',
    cancel: 'Cancel',
    change_password: 'Change Password',
    choose_from_library: 'Choose from Library',
    clear_filters: 'Clear Filters',
    clear: 'Clear',
    close: 'Close',
    confirm: 'Confirm',
    contact_us_now: 'Contact Us Now',
    contact_us: 'Contact us',
    continue: 'Continue',
    create_account: 'Create account',
    deposit_new: '+',
    deposit: 'Top Up Deposit',
    details: 'Details',
    directions: 'Directions',
    done: 'Done',
    download_list: 'Download List',
    download: 'Download',
    e_bidding: 'eBidding',
    filters: 'Filters',
    forgot_password: 'Forgot your password?',
    get_started: 'Get Started',
    go_back: 'Go Back',
    live_auction: 'Live Auction',
    log_in: 'Log in',
    logout: 'Logout',
    make_payment: 'Make Payment',
    menu: 'Menu',
    new_item: 'NEW ITEM',
    next: 'Next',
    offer: 'OFFER',
    ok: 'Ok',
    pre_bid_now: 'Prebid Now',
    pre_bid: 'Pre Bid',
    proceed: 'Proceed',
    record_again: 'Record again',
    register: 'Register',
    remove_watchlist: 'Remove from Watchlist',
    reset_password: 'Reset Password',
    reset: 'Reset',
    retake: 'Retake',
    save_note: 'Save Note',
    save: 'Save',
    search: 'Search',
    sell_with_us: 'Sell with Us',
    send_email: 'Send Email',
    send_message: 'Send message',
    set_max_bid: 'Set Max AutoBid',
    set_pre_bid: 'Set Prebid',
    show_all: 'Show All',
    sign_in: 'Sign in',
    sign_up: 'Sign up',
    skip: 'Skip',
    sold: 'Sold ({{badge}})',
    submit: 'Submit',
    switch_environment: 'Switch Environment',
    take_photo: 'Take Photo',
    top_up: 'TopUp Now!',
    update_bid: 'Update Bid',
    update_max_bid: 'Update Max AutoBid',
    update: 'Update',
    upload: 'Upload',
    view_all_auctions: 'View All Auctions',
    view_all_items: 'View All Assets',
    view_all: 'View All',
    view_details: 'View Details',
    view: 'View',
    watchlist: 'Watchlist',
    withdraw: 'Withdraw',
    withdrawn: 'Withdrawn',
  },
  labels: {
    _balance: 'Balance',
    '03WD': '3 business days',
    '05D': '5 days',
    '05WD': '5 business days',
    '07D': '7 days',
    '07WD': '7 business days',
    '10D': '10 days',
    '14D': '14 days',
    '30D': '30 days',
    account_type_desc: 'Please select one of the following:',
    account_type_header: 'Account type',
    account_type_titel: 'Choose your type of account',
    actions_lowercase: 'Actions',
    actions: 'ACTIONS',
    add_money: 'Add money',
    add_photo: 'Add photo',
    added_to_watchlist: 'Added to your Watchlist',
    agree: 'I agree to the ',
    all_deposits_refundable: 'All deposits are 100% refundable if you do not win.',
    amount_amt: 'Amount due',
    amount_caps: 'AMOUNT',
    amount_left: 'Amount left',
    amount_paying: 'Amount paying',
    amount: 'Amount',
    applied: 'Applied',
    approved: 'Approved',
    asking_bid: 'Asking bid',
    at_auction: 'At auction',
    at_least_8_characters: 'Your password must be at least 8 characters.',
    auction_ended: 'Auction Ended',
    auction_live: 'Auction is LIVE',
    auction_remarks: 'Auction Remarks',
    auction: 'Auction',
    available_balance: 'Available Balance',
    badge: 'Badge',
    balance_available_new: 'Available:',
    balance_available: '{{value}} available',
    balance_hold_new: ' on hold',
    balance_hold: '{{value}} on hold',
    balance: 'Account Balance',
    be_first: 'Be the first!',
    begin_shortly: 'Please wait, the auction will be starting shortly.',
    bid_increment: 'Bid Increment:',
    bid_not_available: 'Bid not Availible',
    bid_this_amount: 'bid this amount',
    bidding_bid_won: 'Bid Won',
    bidding_view_auctions: 'View Auctions',
    bidding_disabled: 'Bidding disabled',
    bidding_enabled: 'Bidding enabled',
    bidding_ended: 'Bidding ended',
    bidding_hold: 'Hold',
    bidding_live: 'Live',
    bidding_lost: 'Lost',
    bidding_lotted: 'Lotted',
    bidding_offer: 'Offer',
    bidding_outbid: 'Outbid',
    bidding_unknown: 'Unknown',
    bidding_highest_bidder: 'Highest bidder',
    bidding_winning: 'You’re winning',
    bidding_won: 'You’ve won!',
    blocked_amount: 'Blocked Amount',
    blocked_balance_alt: 'Blocked Balance',
    blocked_balance: 'Blocked:',
    blocked_deposit_lowercase: 'Blocked deposit',
    blocked_deposit: 'Blocked Deposit',
    broker: 'Broker',
    by_location: 'By location',
    by_make: 'By make',
    by_model: 'By model',
    by_type: 'By type',
    build: 'Build',
    cant_find: 'No vehicles found for "{{value}}" search.',
    car_auction: 'Used Car auction list',
    category: 'Category',
    change_img: 'Change image',
    change_password_lowercase: 'Change password',
    change_password: 'Change Password',
    chassis_number: 'Chassis Number',
    check_image: 'Check image quality',
    check_your_mail: 'Check your mail',
    choose_identity_card: 'Choose Identity Card',
    choose_payment_proof: 'Choose Payment Proof',
    closed: 'Closed',
    color: 'Color',
    condition_type: 'Condition Type',
    condition: 'Condition',
    congratulation: 'Congratulations',
    contain_lowercase_case: 'Your password must contain at least one lowercase letter.',
    contain_upper_case: 'Your password must contain at least one uppercase letter',
    contract: 'Contract',
    copy_link: 'Or copy the link',
    create_account: 'Create your \naccount',
    currency_rm: 'RM',
    current_balance: 'Current Balance',
    current_bid_lowercase: 'current bid',
    current_bid: 'Current bid',
    current_status: 'Current Status',
    date: 'Date',
    dealer_broker_info: "I'm a professional buy/seller",
    dealer: 'Dealer',
    deals_money: 'Find Deals. Make Money.',
    deposit_amount: 'Deposit',
    deposit: 'Deposit',
    device_unique_id: 'Device UID',
    disabled: 'Disabled',
    documents: 'Documents',
    dont_have_account: "Don't have an account? ",
    dot: ' • ',
    drawer_about: 'About Us',
    drawer_auctions: 'Auctions',
    drawer_e_bidding_auction: 'eBidding Auction',
    drawer_ebids: 'My eBids',
    drawer_faq: 'FAQ',
    drawer_help_and_support: 'Help & Support',
    drawer_live_auction: 'Live Auction',
    drawer_my_bids: 'My eBidding Activities',
    drawer_offers_history: 'My Offers History',
    drawer_prebids: 'My Max AutoBids',
    drawer_purchase_history: 'My Purchase History',
    drawer_purchase: 'My Purchases',
    drawer_purchase_v2: 'Purchases & Offers',
    drawer_search_inventory: 'Search Inventory',
    drawer_service: 'Add-on Services',
    drawer_terms_and_conditions: 'Terms and Conditions',
    drawer_title: 'Navigation',
    drawer_transactions: 'My Transaction History',
    drawer_watchlist: 'My Watchlist',
    due_amt: 'Due',
    email_address: 'Email address',
    email_not_valid: 'Please enter correct email',
    email_sent: 'Email sent',
    empty_fullname: 'Please enter your full name',
    empty_username: 'Please enter correct username',
    enabled: 'Enabled',
    end_in: 'Ends in',
    end_time: 'End time',
    end: 'End {{date}}',
    engine_number: 'Engine Number',
    enter_bid_amount: 'Enter the bid amount',
    enter_note: 'Enter Note',
    failed_deposit: 'An unknown error ({{error}}) has occurred while processing your transaction.  Please check with your bank or try again.  If the error continues, you can call our customer support at +60166999170.',
    filtered_items: 'filtered items',
    have_account: 'Already have an account? ',
    hello_user: 'Hello {{name}},',
    high_bid: 'Hight Bid',
    highest_bid_amount: 'highest bid amount',
    highest_bidder: 'You are the highest bidder',
    ic_required: 'IC or passport photo is required',
    id: 'ID',
    incident_type: 'Incident Type',
    increase_max_bid: 'Increase Max AutoBid',
    industrial_auction_list: 'Industrial auction list',
    inspection_remarks: 'Inspection Remarks',
    inspection_report: 'Inspection report',
    insufficient_balance: 'Insufficient Balance',
    insufficient_deposit: 'Insufficient Deposit',
    insurance_report: 'Car insurance report',
    intake_origin: 'Intake Origin',
    item_details: 'Item Details',
    item_live: 'Live Auction',
    item_name: 'Item Name',
    item_on_hold: 'On Hold',
    item_prebid_label: 'Prebid: RM{{amount}}',
    item_static: 'eBidding',
    item: 'Item',
    items: 'items',
    key: 'Key',
    last_bid: 'Your Last Bid',
    last_step_desc: 'Subscribe to our email lists and be the first to know what is coming up for sale.',
    last_step_header: 'Almost finished!',
    last_step_title: 'Never miss a deal',
    latest_auctions: 'Latest Auctions',
    left: 'left',
    live_auction_terms: '*All bids are on an AS IS basis and subject always to Pickles Auctions Auction Terms and Contitions',
    live_now: 'LIVE NOW',
    LIVE: 'LIVE',
    location: 'Location',
    lot_current_bid: 'Current Bid',
    lot_deposit: 'Deposit:',
    lot_is_on_hold: 'This item is on hold and will be \nfeatured in a future auction',
    lot_number: 'Lot:',
    lot_starting_price: 'Starting Bid:',
    lot_stock_number: 'Stock: {{stock}} •  Lot: {{lot}}',
    lost: 'LOST',
    make: 'Make',
    max_bid: 'Your Max AutoBid',
    model: 'Model',
    my_bids: 'My eBids',
    my_last_bid: 'My Last Bid:',
    my_last_bids: 'My eBids',
    my_max_bid: 'My Max AutoBid:',
    my_prebid: 'My Max AutoBid',
    my_prebids:'My Max AutoBids',
    my_watchlist: 'My Watchlist',
    n_a: 'N/A',
    never: 'never',
    next_bid_lowercase: 'next bid',
    next_bid: 'Next Bid:',
    next_steps: 'Please check email for invoices',
    no_auctions_yet: 'No auctions yet',
    no_auctions: 'There is no auction at this moment.',
    no_blocked_deposits: "You don't have blocked deposits.",
    no_e_bidding_auctions: 'There are no eBidding auctions at this moment.',
    no_ebids_yet: 'No eBids yet',
    no_ebids: 'You have no eBids at this time',
    no_fees: 'no fees',
    no_items_yet: 'No items yet',
    no_items: 'No items in this auction.',
    no_live_auctions: 'There are no Live auctions at this moment.',
    no_my_bids: 'You have no bids at this time',
    no_offer_yet: 'No offer yet',
    no_offers: 'You have no offers at this time',
    no_prebid_yet: 'No Max AutoBids yet',
    no_prebids: 'You have no Max AutoBids at this time',
    no_price: 'RM -',
    no_purchase_yet: 'No purchase yet',
    no_purchases: 'You have no purchases at this time',
    no_result_found: 'No result found',
    no_sale: 'no sale',
    no_search_result: 'There are no items available based on your search input',
    no_transactions: 'You have no transactions yet',
    no_watchlist_yet: 'No watchlist yet',
    no_watchlist: "You haven't added anything to your Watchlist",
    now_bidding: 'Now Bidding',
    offer_amount: 'Offer Amount',
    offer_for: 'Offer for {{amount}}',
    offer_this_amount: 'offer at this amount',
    offers: 'Offers',
    one_touch_bidding_disabled: 'One Touch Bidding Disabled',
    one_touch_bidding_enabled: 'One Touch Bidding Enabled',
    or: 'or',
    paid: 'Paid',
    paid_on: 'Paid on',
    partial_submitted: 'Partial submitted',
    password_not_match: 'Passwords do not match',
    password_rules: 'Your password must be at least 8 characters and contain at least 1 uppercase, 1 lowercase, and 1 number!',
    password_to_switch_environment: 'Put password to switch environment.',
    password: 'Password',
    pay_by: 'Pay by',
    pay_by_date: 'Pay by date',
    pay_with: 'Pay with',
    payable_to_pickles: 'Payable to Pickles',
    payable_to_seller: 'Payable to Seller',
    payment_due: 'Payment Due',
    payment_instructions: 'Payment Instructions',
    payment_method_web: 'Payment method',
    payment_pending: 'Payment pending',
    payment_proof: 'Payment proof',
    payment_ref_no: 'Payment Reference Number',
    payment_submission_history: 'Payment Submission History',
    pending_account: 'Your account is pending approval',
    pending_approval: 'Pending approval',
    personal_details: 'Personal details',
    personal_id: 'Personal ID or Passport',
    phone_not_valid: 'Please enter correct phone',
    please_fill_all_fields: 'Please fill in all fields',
    prebid_label: 'Prebid',
    profile_details: 'Profile details',
    profile_info: 'Profile info',
    profile: 'PROFILE',
    public_info: 'I buy/sell for myself or family',
    public: 'Public',
    read_and_agree: 'I have read and agree to the',
    recent_industrial_items: 'Recently Added Industrial Assets',
    recent_salvage_items: 'Recently Added Salvage Assets',
    recent_used_cars: 'Recently Added Used Cars',
    recent_used_motorcycles: 'Recently Added Used Motorcycles',
    recommended_auctions: 'Recommended for you',
    reg_number: 'Reg Number',
    register_complete: "You're all set",
    rejected: 'Rejected',
    remember_me: 'Remember me',
    removed_from_watchlist: 'Removed from your Watchlist',
    reserve: 'Reserve',
    reserved_price: 'Reserved price',
    reset_password: 'Reset password',
    salvage_auction_list: 'Salvage auction list',
    search_our_inventory: 'Search Inventory',
    search_purchases: 'Search purchases',
    select_payments: 'Please select one of the following payment methods:',
    selected: 'Selected',
    sending_bid: 'Sending bid...',
    set_max_bid_lowercase: 'set Max AutoBid',
    share_via: 'Share via',
    sign_in: 'Sign in to your account below.',
    sign_me_weekly: 'Yes, sign me up for the weekly ',
    sign_me: 'Yes, sign me up for the ',
    socket_id: 'Connection ID',
    sold_amt: 'Sold Amount',
    sold_date: 'Sold date',
    sold_on: 'Sold on',
    sold_this_amount: 'sold at this amount',
    sold: 'SOLD',
    sorry: 'Sorry!',
    source_type: 'Source Type',
    specifications: 'Specifications',
    start_in: 'Starts in',
    start_price: 'Starting price',
    start_soon: 'Starts soon',
    start_time: 'Start time',
    starting_bid: 'Starting bid',
    STATIC: 'eBidding',
    status: 'Status',
    stock_no: 'Stock No/Lot no',
    stock_number: 'Stock',
    submitted: 'Submitted',
    supported_payment_methods: 'Supported payment methods',
    supporting_document: 'Supporting document #1',
    take_photo: 'Take photo',
    tap_to_bid: 'Tap to Bid',
    thanks_for_attend: 'Thank you for attending the sale.',
    time_date: 'Time/Date',
    time_out: 'Time out',
    time_remaining: 'Time remaining',
    time: 'Time',
    to_be_announced: 'To be announced',
    today_lowercase: 'Today',
    today: 'TODAY',
    top_up_securely: 'Top up securely',
    top_up: 'TopUp',
    total_bids: 'Total bids',
    total_items: 'total items',
    tow_by: 'Tow by',
    transaction_history_lowercase: 'Transaction history',
    transaction_history: 'Transaction History',
    transactions: 'TRANSACTIONS',
    triple_dot: '•••',
    type_lowercase: 'Type',
    type_of_doc: 'Type of doc',
    type: 'TYPE',
    units: 'units',
    unpaid: 'Unpaid',
    upcoming_auctions: 'Upcoming Auctions',
    update_max_bid_lowercase: 'update Max AutoBid',
    update_require_message: 'Updates bring you the best new features. We detected that you are still using an older version of the app, please install the latest version to continue to enjoy bidding online with Pickles.',
    upload_avatar: '+ Upload profile photo',
    upload_id: 'Upload Identity Card \nor Passport image',
    upload_receipt: 'Upload receipt',
    uploaded_id: 'Identity Card or Passport\n',
    useful_links: 'Here are some helpful links instead:',
    variant: 'Variant',
    vendor: 'Vendor',
    version: 'Version',
    view_contract: 'View Contract',
    view_history: 'View History',
    view_time: 'Viewing time',
    welcome: 'Welcome',
    withdraw: 'Withdraw',
    yard_location: 'Yard Location',
    years_of_manufacture: 'Year of Manufacture',
    you_are_logged_with: 'You are logged with:',
    you_available_deposit: 'Your Available Deposit',
    you_highest: 'You are the highest bidder. If you\'d like to increase your maximum bid, tap "My Max AutoBid"',
    you_won: 'YOU WON!',
    you: 'YOU',
    your_bid: 'Your bid',
    your_deposit_has_been_received: 'Your deposit has been received by MolPay.  Pull this screen down to refresh if the new balance is not shown in about 1 minute.',
    your_max_bid_lowercase: 'your Max AutoBid',
    your_max_bid: 'Your Max AutoBid',
    your_notes: 'Your Notes',
    your_pre_bid: 'Your Prebid',
    your_prebid_accepted: 'Your Max AutoBid of {{amount}} is accepted',
    your_wallet: 'Your Wallet',
  },
  captions: {
    be_first_bidder: 'Be the first to bid!',
    check_auctions: 'Auction ended. Go to homepage for more auctions!',
    eligible_for_offer: 'Eligible for Offer!',
    eligible_to_buy: 'Eligible for Purchase!',
    highest_bidder: 'You’re the Highest Bidder!',
    late_bid_placed: 'Your bid was placed late',
    lost: 'You LOST!',
    lotted: 'Item is lotted',
    offer: 'You’ve got an offer!',
    outbid: 'You were outbid!',
    outbid_by_someone: 'You were outbid by someone else',
    send_bid_delay: 'Your bid is taking longer than expected to process. Please wait for a moment or check your network connection.',
    start_bidding: 'Start Bidding Now!',
    top_up: 'Topup {{amountToTopUp}} to bid!',
    updating_result: 'Updating Bid Results...',
    winning: 'You’re winning!',
    won: 'You WON!',
  },
  fields: {
    account_status: 'Account Status',
    bid_now: 'Bid Now',
    brand: 'Brand',
    confirm_new_password: 'Confirm New Password',
    confirm_password: 'Confirm password',
    current_password: 'Current Password',
    dealer: 'Dealer',
    email_address: 'Email Address',
    full_name: 'Enter your full name',
    ic_number: 'Enter your Personal ID or Passport',
    identity_card_selected: 'Identity Card selected',
    identity_card: 'Identity Card',
    ill_purchasing_items_as_a: "I'll purchasing items as a...",
    keyword: 'Keyword',
    mobile_number: 'Mobile number',
    model: 'Model',
    name: 'Name',
    new_email_address: 'Enter your email',
    new_password: 'New Password',
    passport_number: 'IC/Passport number',
    passport: 'Enter your IC number or Passport',
    password: 'Enter a safe password',
    payment_ref_no: 'Enter payment ref. #',
    phone_number: 'Enter your phone number',
    place_your_bid: 'Place your bid',
    repeat_password: 'Repeat Password',
    search_for_an_item: 'Search for an item or auction...',
    search_input: 'Search items & auctions',
    search_watchlist: 'Search watchlist',
    search: 'Search...',
    select_auction_category: 'Auction Category',
    select_auction_type_web: 'Auction type',
    select_auction_type: 'Auction Type',
    select_condition_type: 'Select Condition Type',
    select_item_type_web: 'Item type',
    select_item_type: 'Item Type',
    select_location: 'Select location',
    select_make: 'Select make',
    select_model: 'Select model',
    select_type: 'Select type',
    turn_notification: 'Turn Off/On Notification',
    username: 'Username',
    yard: 'Yard',
    year_from: 'Year From',
    year_to: 'Year To',
    year: 'Year',
    your_name: 'Your Name',
  },
  infos: {
    account_pending_title_new: 'Your account is still pending approval.',
    account_pending: 'We will email you when we have completed verification. The process typically takes about 1 business day.',
    after_login: 'After you login, you will see your eBidding activities here.',
    amount_paying_not_exceed_outstanding: 'Amount paying should not be more than outstanding amount',
    auction_not_started: 'This item is ready for sale but the auction has not yet started. To get notified when the bidding starts, click on the Watchlist above. ',
    auction_started: 'The auction for this item has already started. To bid on this item you must attend the auction. Good luck bidding!',
    check_payment_reference: 'Make sure the Payment Reference ID and amount are clear.',
    current_bid: 'Current Bid',
    custom_bid_info_more_than: 'By setting a Max AutoBid our system will bid for you against other bidders until your maximum bid is reached.  To set a Max AutoBid you must bid greater than {{nextValue}} for your bid to be accepted.  Max AutoBids can be increased in the future if needed.',
    custom_bid_info_prebid: 'By setting a Max AutoBid our system will bid for you against other bidders until your maximum bid is reached.  Max AutoBids can be increased in the future if needed.',
    custom_bid_info: 'By setting a Max AutoBid our system will bid for you against other bidders until your maximum bid is reached.  To set a Max AutoBid you must bid at least {{nextValue}} or greater for your bid to be accepted.  Max AutoBids can be increased in the future if needed.',
    drawer_not_logged: 'To enjoy the full access to Pickles Auctions, please create an account or log in.',
    duplicate_payment_reference: 'This Payment Reference Number already exists. Kindly submit a different Payment Reference Number.',
    email_sent: 'An email has been sent with instructions on how to reset your password',
    exceed_items_per_submission: 'You can only apply payments up to maximum of {{numberOfItems}} items.',
    exceed_payment_reference_length: 'Payment Reference Number should not exceed {{allowedPaymentReferenceLength}} characters.',
    forgot_pass: 'We will send you an email with instructions on how to reset your password.',
    for_bank_details_and_contact: 'For Bank A/C details and contact info',
    get_started: 'Get Started',
    insufficient_balance_topup: 'You do not have sufficient balance to bid on this item, please top-up your deposit or view your blocked deposit items under your "Top Up" screen.',
    insufficient_deposit: 'The required deposit amount is {{deposit}}, but your available balance is {{available}}',
    insufficient_balance: 'You have won this item with your deposit blocked in another auction. Please call {{phone}} to topup your balance and complete your purchase.',
    live_bidding_active: 'You have active bidding session for Live Auction!',
    live_coming_soon: 'Live auction for our website is currently being developed. Please check back soon! Kindly contact {{phone}} for more information.',
    login_info: 'You must have sufficient balance for the item you are bidding on. The minimum deposit required for each item is listed in the description. The correct amount will be immediately deducted from your account when you are the winning bidder.',
    message_sent_error: 'Error occurred while sending message!',
    message_sent: 'Your message has been sent!',
    message_subscribed: 'You have subscribed to our company newsletter',
    meta_description: 'Find Deals. Make Money. Bid and buy easily on insurance salvage, bank reposession, used cars, automotive parts using our mobile bidding app or website.',
    meta_title: 'Pickles Auctions buy industrial, cars, and salvage',
    must_login_favorite: 'You must be logged in to add items to your Watchlist.',
    must_login_note: 'You must be logged in to add note to the item.',
    must_login_to_bid: 'You must be logged in or sign-up to bid on this item.',
    no_longer: 'This item is no longer available',
    not_logged: 'You are not logged in. Log in or sign up to attend auction, purchase items and more.',
    on_hold: 'This item is on hold for a future auction. To get notified when the bidding starts, add it to your Watchlist.',
    or_send: 'or send us an email to',
    payment_guideline_instruction_1: 'You can make payments for max of 5 items at a time',
    payment_guideline_instruction_2: 'You can only submit 1 receipt as proof of payment',
    payment_notification: 'You have {{paymentTerm}} to make payment for the purchase, failing to do so may incur a late storage penalty fee.',
    payment_success: 'Status will be updated in item page after verification',
    payment_to_seller_important_note: 'Important: this section can be only used to make payments to Pickles.',
    payment_to_seller_instruction_intro: 'How to make seller payments:',
    payment_to_seller_instruction_1: 'Go to Purchases & Offers > Unpaid',
    payment_to_seller_instruction_2: 'Tap on "View details" on the item for payment',
    payment_to_seller_instruction_3: 'Check "View contract" for Seller Bank Account Details and Contact Number',
    payment_to_seller_instruction_4: 'Make the payment and then share the proof of payment to the Seller',
    payment_to_seller_instruction_5: 'The Seller will send a Release Letter by email to you and to Pickles when the payment is verified',
    payment_to_seller_instruction_6: 'Pickles staff will approve the payment, which will reflect on the item Detail screen',
    pickles_verify_seller_payment: 'Payment to Seller will be verified by Pickles',
    pre_bid: 'Prebid now and we\'ll bid up to your Max AutoBid',
    refund: 'To request a refund, please email refunds@pickles.my with your username and your bank account details. \n\nOur team will proceed with the refunds once we have received your details.',
    reset_pass_email_sent: 'An email has been sent to reset your password.',
    reset_pass_sent: 'Reset Password Email successfully sent.',
    reset_password: 'We will email you the instructions to reset your password.',
    still_problem: 'If you still have problems or you have not received the email after a few minutes, give us a call at',
    wait_1_hour: 'Your account is not yet verified, please allow 1 business day for verification, or contact our support team for immediate assistance',
    we_will_look: 'and we will look forward to helping you.',
    you_must_logged: 'You must be logged in to view starting price and to bid.',
    your_account_has_not: 'Your account has not yet been approved. Please contact our customer support',
    your_bid_must_be_higher: 'Your bid must be higher then {{value}}',
  },
  status: {
    e_bidding: 'E-BIDDING',
    live_auction: 'LIVE',
    not_current_item: 'Not Current Item',
    on_hold: 'On Hold',
    outbid_lowercase: 'Outbids',
    outbid: 'OUTBID',
    winning_lowercase: 'Winning',
    winning: 'WINNING',
  },
  titles: {
    all_auctions: 'All Auctions',
    apply_payment: 'Apply Payment',
    auction_detail: 'Auction details',
    awesome_news: 'Awesome news!',
    bid_history: 'Bid History',
    car_details: 'Item Detail',
    coming_soon: 'Coming Soon',
    connect_acc: 'Connect account',
    custom_bid: 'Custom Bid',
    dashboard: 'Dashboard',
    duplicate_payment_reference: 'Duplicate payment',
    ebidding_auctions: 'eBidding Auctions',
    enter_note: 'Enter Note',
    failure: 'Failure!',
    filters: 'Filters',
    gallery: 'Gallery',
    item_photos: 'Item Photos',
    live_auctions: 'Live Auctions',
    make_payment: 'Make Payment',
    my_account_web: 'My account',
    my_account: 'My Account',
    my_bidding_activity: 'My eBidding Activities',
    my_bids: 'My eBids',
    my_offers: 'My Offers',
    my_prebids: 'My Max AutoBids',
    my_purchases: 'My Purchases',
    my_purchases_v2: 'Purchases & Offers',
    my_watchlist: 'My Watchlist',
    no_offers: 'No offers',
    notes: 'Notes',
    notice: 'Notice',
    offers_history: 'Offers History',
    offers: 'Offers',
    payment_submission_guidelines: 'Payment Submission Guidelines',
    payment_success: 'Your Payment Proof has been submitted successfully',
    pickles_auctions: 'Pickles Auctions',
    pos: 'Proclamation of Sale',
    purchase_details: 'Purchase Details',
    purchases: 'Purchases',
    search_result: 'Search Result',
    search: 'Search',
    select_date: 'Select date',
    select_items: 'Select items',
    sell_with_us: 'Sell with Us',
    share: 'Share',
    sorry: 'Sorry!',
    submit_payment_proof: 'Submit Payment Proof',
    success: 'Success!',
    top_up: 'Top Up',
    update_require: 'Keep Pickles app up-to-date!',
    user_offers: 'Offers',
    vehicle_list: 'Vehicle List',
  },
  messages: {
    Accepted: 'Your bid has been accepted',
    AdjustDown: 'Bid adjusted',
    CreditExceed: 'Deposit exceeded',
    FlaggedNoBuy: 'Cannot buy',
    OtherBid: 'Another bid first',
    OutBid: 'You were OUTBID!',
    PriorProposal: 'Another bid first',
    ReppingItem: 'You own this item',
    reserve_price_info: 'This item does not disclose the reserve price.',
    Reset: 'Bidding reset',
    ToFloor: 'Given to floor',
    TooLow: 'Bid too low',
    your_bid: 'Your bid: {{value}}',

  },
  errors: {
    contact_support: 'There is an error in registration. Please contact our customer support',
    email_already_exists: 'Email already exists. Do you need to reset your password?',
    email_exists: 'This email address already registered',
    email_not_register: 'This email may not be registered with us',
    error_oops: 'Oops!',
    error_title: 'Error',
    ic_number_already_exists: 'IC Number already exists. Do you need to reset your password?',
    ic_number_exists: 'This IC number already registered',
    login_user_failure: 'You have entered an invalid username or incorrect password',
    token_expired_error: 'Your login session expired. Please login again.',
    auth_error:'Sorry, there is an error {{errorCode}} occurred while processing your request. Please try in a few moments, or contact {{phone}} if the problem still persist.',
    client_error: 'Sorry, there is an error {{errorCode}} occurred while processing your request. Please try in a few moments or contact {{phone}}.',
    server_error: 'There is an error occurred while processing your request. Please try in a few moments, or contact {{phone}}.',
    timeout_error: 'Server is taking too long to respond. Please try in a few moments or contact {{phone}}.',
    connection_error: 'It seems that you do not have an active internet connection. Please try checking your data connection or reconnecting to Wi-Fi. Please contact {{phone}} if the problem still persists.',
    network_error: 'It seems that you do not have an active internet connection. Please try checking your data connection or reconnecting to Wi-Fi. Please contact {{phone}} if the problem still persists.',
    cancel_error: 'It seems that your connection has an issue. Please try again.',
    no_internet: 'No Internet',
    offline: 'Offline',
    page_not_found: 'We can’t seem to find the page you’re looking for.',
    please_fill_next_fields: 'Please fill valid data in next fields',
    register_another_email: 'The email address you provided is already registered. Kindly try with a different email address.',
    register_another_ic_number: 'The IC number you provided is already registered. Kindly try with a different IC number.',
    register_another_username: 'The username you provided is already registered. Kindly try with a different username.',
    register_user_failure: 'Register user failure',
    server_fields: {
      buyer_type: 'Buyer Type',
      username: 'Full Name',
      gov_id: 'IC Number',
      email: 'Email Address',
      phone: 'Phone Number',
    },
    still_pending_approval_new: 'It typically takes 1 business day. If you need urgent approval, call {{phone}}.',
    still_pending_approval: 'Your account is still pending approval. Please allow 1 business day for processing, for urgent approvals please call {{phone}}.',
    transaction_limit: 'Online top-up currently has a single transaction limit of {{maxAmount}}. Kindly enter any amount from {{minAmount}} to {{maxAmount}} to proceed',
    fpx_transaction_limit_min: 'Transaction amount is lower than the minimum limit RM1.00',
    payment_method_disabled: 'Selected payment method has been disabled. Please refresh and try another method',
    username_already_exists: 'Username already exists. Do you need to reset your password?',
    username_exists: 'This username already registered',
  },
  transactions: {
    auto_settled: 'Auto Settled',
    cash: 'Cash',
    check: 'Check',
    credit_card: 'Credit Card',
    deposit: 'Deposit',
    download: 'Download', // download a receipt label
    eft: 'EFT',
    invoice_consigner: 'Consigner Fee',
    invoice: 'Invoice',
    item_buyer: 'Buyer Fee',
    item_deposits: 'Item Deposit',
    molpay: 'MOL Pay',
    noTransactions: 'No Transactions', // No purchases
    payment_method: 'Payment Method',
    payment: 'Payment',
    refund: 'Refund',
    title: 'Transaction History', // Transaction History
    view_older: 'For older transaction records, please contact info@pickles.my',
  },
  socket: {
    latency: 'Latency',
    ebid: {
      connect_error: 'Connection error',
      connected: 'E-Bidding Connected',
      disconnect: 'Connection Lost',
      reconnect_error: 'Reconnecting...',
      reconnect_failed: 'Reconnect failed',
      reconnected: 'Connection established.',
      reconnecting: 'Reconnecting...',
      retries: 'Retries Attempt',
    },
    live: {
      connect_error: 'Connection error',
      connect_timeout: 'Connection timeout',
      connected: 'Live Auction Connected',
      disconnect: 'Connection Lost',
      reconnect_error: 'Reconnecting...',
      reconnect_failed: 'Reconnect failed',
      reconnected: 'Connection established.',
      reconnecting: 'Reconnecting...',
      retries: 'Retries Attempt',
    },
  },
  fpx: {
    amount: 'Amount',
    pay_with: 'Pay with',
    payment_mode: 'Payment mode',
    select_bank: 'Select bank',
    proceed: 'Proceed',
    cancel: 'Cancel',
  }
};
