import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IAuction, IBidding, IEbid, IItem, IUser } from '../../models';
import { RESPONSE_TYPE } from '../../models/item/item.flat.types';
import * as IRequest from '../../services/api/request.types';
import * as IResponse from '../../services/api/response.types';
import { arrayItemsToMap, SourceMap } from '../../utils/helpers';
import { userActions } from '../user/slice';
import { composeItemFromFlatResponse, composeItemFromSearchItemResponse } from './utils';

export interface ItemState {
  items: SourceMap<IItem.Item>;
  recommendations: IItem.Item[];
  latestVehicles: IItem.Item[];
  recentUsedMotorcycleItemIds: number[];
  recentIndustrialItemIds: number[];
  recentSalvageItemIds: number[];
  searchResults: IItem.Item[];
  favoritesIds: number[];
  favoritesAll: IItem.Favorite[];
}

const INITIAL_STATE: ItemState = {
  items: {},
  recommendations: [],
  latestVehicles: [],
  recentUsedMotorcycleItemIds: [],
  recentIndustrialItemIds: [],
  recentSalvageItemIds: [],
  searchResults: [],
  favoritesIds: [],
  favoritesAll: [],
};

type ItemSourceMap = SourceMap<IItem.Item>;

type SubmitPayment = {
  fields: IItem.SubmitPaymentFields;
  paymentProof: IUser.ImagePickerResponse;
};

// const itemEntity = createEntityAdapter<ItemState>();

const itemSlice = createSlice({
  name: 'item',
  initialState: INITIAL_STATE,
  reducers: {
    getItem: {
      reducer: (_state, _action: PayloadAction<number>) => {},
      prepare: (itemId: number) => ({ payload: itemId }),
    },
    getItemSuccess: {
      reducer: (state, action: PayloadAction<{ id: number; item: IItem.Flat.ItemFlat }>) => {
        const { id, item } = action.payload;

        const formattedItem = composeItemFromFlatResponse(item, IItem.Flat.RESPONSE_TYPE.ITEM);

        state.items[id] = _.merge(state.items[id], formattedItem);
      },
      prepare: (id: number, item: IItem.Flat.ItemFlat) => ({ payload: { id, item } }),
    },
    getItemFailure: (_state, _action: PayloadAction<string>) => {},

    getItemsByIds: (_state, _action: PayloadAction<IRequest.ItemsRequestBody>) => {},

    getItems: {
      reducer: (_state, _action: PayloadAction<IRequest.ItemsRequestBody>) => {},
      prepare: (payload: IRequest.ItemsRequestBody) => ({ payload }),
    },
    getItemsSuccess: {
      reducer: (state, action: PayloadAction<IResponse.ItemsResponseData>) => {
        const { items } = action.payload;

        [...items].forEach((item) => {
          const composed = composeItemFromFlatResponse(item, IItem.Flat.RESPONSE_TYPE.ITEM);
          state.items[composed.id] = _.merge(state.items[composed.id], composed);
        });
      },
      prepare: (payload: IResponse.ItemsResponseData) => ({ payload }),
    },
    getItemsFailure: (_state, _action: PayloadAction<string>) => {},

    getRecommendationsList: {
      reducer: (_state, _action: PayloadAction<{ page: number }>) => {},
      prepare: (page: number) => ({ payload: { page } }),
    },
    getRecommendationsListSuccess: {
      reducer: (state, action: PayloadAction<IResponse.RecommendationsResponseData>) => {
        const recommendations = action.payload;
        const formattedItems: ItemSourceMap = {};

        const _recommendations = recommendations.map((item) => {
          const composed = composeItemFromFlatResponse(
            item,
            IItem.Flat.RESPONSE_TYPE.RECOMMENDATION,
          );
          formattedItems[composed.id] = composed;
          return composed;
        });

        state.recommendations = _recommendations;
      },
      prepare: (payload: IResponse.RecommendationsResponseData) => ({ payload }),
    },
    getRecommendationsListFailure: (_state, _action: PayloadAction<string>) => {},

    getLatestVehicles: () => {},
    getLatestVehiclesSuccess: {
      reducer: (state, action: PayloadAction<IResponse.ItemsResponseData>) => {
        const { items } = action.payload;

        state.latestVehicles = items.map((item) => {
          return composeItemFromFlatResponse(item, IItem.Flat.RESPONSE_TYPE.ITEM);
        });
      },
      prepare: (payload: IResponse.ItemsResponseData) => ({ payload }),
    },
    getLatestVehiclesFailure: (_state, _action: PayloadAction<string>) => {},

    getRecentUsedMotorcycles: () => {},
    getRecentUsedMotorcyclesSuccess: {
      reducer: (state, action: PayloadAction<IResponse.ItemsResponseData>) => {
        const { items } = action.payload;

        const itemIds: number[] = [];

        items?.forEach((item) => {
          itemIds.push(item.id);
          const composedItem = composeItemFromFlatResponse(item, IItem.Flat.RESPONSE_TYPE.ITEM);
          const existingItem = state.items[item.id];
          state.items[item.id] = existingItem ? _.merge(existingItem, composedItem) : composedItem;
        });

        state.recentUsedMotorcycleItemIds = itemIds;
      },
      prepare: (payload: IResponse.ItemsResponseData) => ({ payload }),
    },
    getRecentUsedMotorcyclesFailure: (_state, _action: PayloadAction<string>) => {},

    getRecentIndustrialItems: () => {},
    getRecentIndustrialItemsSuccess: {
      reducer: (state, action: PayloadAction<IResponse.ItemsResponseData>) => {
        const { items } = action.payload;

        const itemIds: number[] = [];

        items?.forEach((item) => {
          itemIds.push(item.id);
          const composedItem = composeItemFromFlatResponse(item, IItem.Flat.RESPONSE_TYPE.ITEM);
          const existingItem = state.items[item.id];
          state.items[item.id] = existingItem ? _.merge(existingItem, composedItem) : composedItem;
        });

        state.recentIndustrialItemIds = itemIds;
      },
      prepare: (payload: IResponse.ItemsResponseData) => ({ payload }),
    },
    getRecentIndustrialItemsFailure: (_state, _action: PayloadAction<string>) => {},

    getRecentSalvageItems: () => {},
    getRecentSalvageItemsSuccess: {
      reducer: (state, action: PayloadAction<IResponse.ItemsResponseData>) => {
        const { items } = action.payload;

        const itemIds: number[] = [];

        items?.forEach((item) => {
          itemIds.push(item.id);
          const composedItem = composeItemFromFlatResponse(item, IItem.Flat.RESPONSE_TYPE.ITEM);
          const existingItem = state.items[item.id];
          state.items[item.id] = existingItem ? _.merge(existingItem, composedItem) : composedItem;
        });

        state.recentSalvageItemIds = itemIds;
      },
      prepare: (payload: IResponse.ItemsResponseData) => ({ payload }),
    },
    getRecentSalvageItemsFailure: (_state, _action: PayloadAction<string>) => {},

    getPurchases: {
      reducer: (_state, _action: PayloadAction<{ page: number }>) => {},
      prepare: (page: number) => ({ payload: { page } }),
    },
    getPurchasesSuccess: {
      reducer: (state, action: PayloadAction<IResponse.PurchasesResponseData>) => {
        const purchases = action.payload;
        for (const purchase of [...purchases]) {
          const composed = composeItemFromFlatResponse(purchase, IItem.Flat.RESPONSE_TYPE.PURCHASE);
          const existing = state.items[composed.id];
          state.items[composed.id] = _.merge(existing, composed);
        }
      },
      prepare: (payload: IResponse.PurchasesResponseData) => ({ payload }),
    },
    getPurchasesFailure: (_state, _action: PayloadAction<string>) => {},

    // TODO: Rename all purchase V2 related reducers to just purchase once PSD is implemented to webApp as well
    getPurchasesV2: {
      reducer: (
        _state,
        _action: PayloadAction<{ page: number; status: IItem.PurchaseStatusType }>,
      ) => {},
      prepare: (page: number, status: IItem.PurchaseStatusType) => ({ payload: { page, status } }),
    },
    getPurchasesV2Success: {
      reducer: (state, action: PayloadAction<IResponse.PurchasesV2ResponseData>) => {
        const purchases = [...action.payload];
        for (const purchase of purchases) {
          const composed = composeItemFromFlatResponse(
            purchase,
            IItem.Flat.RESPONSE_TYPE.PURCHASEV2,
          );
          const existing = state.items[composed.id];
          state.items[composed.id] = _.merge(existing, composed);
        }
      },
      prepare: (payload: IResponse.PurchasesV2ResponseData) => ({ payload }),
    },
    getPurchasesV2Failure: (_state, _action: PayloadAction<string>) => {},

    clearPurchasesV2: {
      reducer: (state, action: PayloadAction<IItem.Item[]>) => {
        const purchases = action.payload;
        for (const purchase of [...purchases]) {
          state.items[purchase.id] = { ...purchase, purchaseV2: undefined };
        }
      },
      prepare: (payload: IItem.Item[]) => ({ payload }),
    },

    getPaymentDetails: {
      reducer: (_state, _action: PayloadAction<{ itemId: number }>) => {},
      prepare: (itemId: number) => ({ payload: { itemId } }),
    },
    getPaymentDetailsSuccess: {
      reducer: (state, action: PayloadAction<IResponse.PaymentDetailsResponseData>) => {
        const paymentDetails = action.payload;
        const composed = composeItemFromFlatResponse(
          paymentDetails,
          IItem.Flat.RESPONSE_TYPE.PAYMENT_DETAILS,
        );
        const existing = state.items[composed.id];
        state.items[composed.id] = _.merge(existing, composed);
      },
      prepare: (payload: IResponse.PaymentDetailsResponseData) => ({ payload }),
    },
    getPaymentDetailsFailure: (_state, _action: PayloadAction<string>) => {},

    submitPayment: {
      reducer: (_state, _action: PayloadAction<SubmitPayment>) => {},
      prepare: (fields: IItem.SubmitPaymentFields, paymentProof: IUser.ImagePickerResponse) => ({
        payload: { fields, paymentProof },
      }),
    },
    submitPaymentSuccess: () => {},
    submitPaymentFailure: (_state, _action: PayloadAction<string>) => {},

    createPayment: {
      reducer: (_state, _action: PayloadAction<IRequest.PaymentSubmissionRequestBody>) => {},
      prepare: (fields: IRequest.PaymentSubmissionRequestBody) => ({ payload: fields }),
    },
    createPaymentSuccess: {
      reducer: (_state, _action: PayloadAction<number>) => {},
      prepare: (buyerPaymentId: number) => ({ payload: buyerPaymentId }),
    },
    createPaymentFailure: (_state, _action: PayloadAction<string>) => {},

    uploadPaymentProof: {
      reducer: (
        _state,
        _action: PayloadAction<{ buyerPaymentId: number; image: IUser.ImagePickerResponse }>,
      ) => {},
      prepare: (buyerPaymentId: number, image: IUser.ImagePickerResponse) => ({
        payload: { buyerPaymentId, image },
      }),
    },

    getOffers: () => {},
    getOffersSuccess: {
      reducer: (state, action: PayloadAction<IItem.Flat.OfferFlat[]>) => {
        const offers = action.payload;

        for (const offer of [...offers]) {
          const composed = composeItemFromFlatResponse(offer, IItem.Flat.RESPONSE_TYPE.OFFER);
          const existing = state.items[composed.id];
          state.items[composed.id] = _.merge(existing, composed);
        }
      },
      prepare: (payload: IItem.Flat.OfferFlat[]) => ({ payload }),
    },
    getOffersFailure: (_state, _action: PayloadAction<string>) => {},
    setOffers: {
      reducer: (
        state,
        action: PayloadAction<{ itemId: number; offer: Partial<IItem.Offer> }[]>,
      ) => {
        action.payload.forEach(({ itemId, offer }) => {
          const existing = state.items[itemId];

          if (existing) {
            existing.offer = _.merge(existing.offer, offer);
          }
        });
      },
      prepare: (payload: { itemId: number; offer: Partial<IItem.Offer> }[]) => ({ payload }),
    },

    getPrebids: () => {},
    getPrebidsSuccess: {
      reducer: (state, action: PayloadAction<IResponse.GetPrebidsData>) => {
        const prebids = action.payload;
        for (const prebid of [...prebids]) {
          const composed = composeItemFromFlatResponse(prebid, IItem.Flat.RESPONSE_TYPE.PREBID);
          const existing = state.items[composed.id];
          state.items[composed.id] = _.merge(existing, composed);
        }
      },
      prepare: (payload: IResponse.GetPrebidsData) => ({ payload }),
    },
    getPrebidsFailure: (_state, _action: PayloadAction<string>) => {},

    getFavorites: {
      reducer: (_state, _action: PayloadAction<number>) => {},
      prepare: (page: number) => ({ payload: page }),
    },
    getFavoritesSuccess: {
      reducer: (state, action: PayloadAction<IResponse.GetFavoritesResponseData>) => {
        const favorites = action.payload;
        const favoritesIds = favorites.map((item) => item.itemId);
        const composedFavorites = favorites.map((item) => {
          return composeItemFromFlatResponse(item, IItem.Flat.RESPONSE_TYPE.FAVORITE);
        });

        state.items = _.merge(state.items, arrayItemsToMap<IItem.Item>(composedFavorites));
        // To de-duplicate same favoritesIds if `getFavorites` was called before in other pages
        state.favoritesIds = _.union(state.favoritesIds, favoritesIds);
      },
      prepare: (payload: IItem.Flat.FavoriteFlat[]) => ({ payload }),
    },
    getFavoritesFailure: (_state, _action: PayloadAction<string>) => {},

    getFavoritesAll: () => {},
    getFavoritesAllSuccess: {
      reducer: (state, action: PayloadAction<IResponse.GetFavoritesAllResponseData>) => {
        const favorites = action.payload;
        const formattedItems: ItemSourceMap = {};

        let prevItemState = { ...state.items };
        for (const favoritedItem of [...favorites]) {
          const prevItem = state.items[favoritedItem.itemId];

          if (prevItem) {
            const item: Item = { ...prevItem, favorite: favoritedItem };
            prevItemState = { ...prevItemState, [item.id]: item };
            break;
          }

          // TODO: what do we do for those favourited items that are not currently existed in items state?
          // favorites/all endpoint only return id, itemId, and favorite fields
          // formattedItems[favoritedItem.itemId] = favoritedItem;
        }

        state.items = { ...prevItemState, ...formattedItems };
        state.favoritesAll = favorites;
        _logger.groupEnd();
      },
      prepare: (payload: IResponse.GetFavoritesAllResponseData) => {
        return { payload };
      },
    },
    getFavoritesAllFailure: (_state, _action: PayloadAction<string>) => {},

    getFavoritesIds: () => {},
    getFavoritesIdsSuccess: {
      reducer: (state, action: PayloadAction<{ itemId: number }[]>) => {
        const favorites = action.payload;
        const favoritesIds = favorites.map((item) => item.itemId);
        state.favoritesIds = favoritesIds;
      },
      prepare: (payload: { itemId: number }[]) => {
        return { payload };
      },
    },
    getFavoritesIdsFailure: (_state, _action: PayloadAction<string>) => {},

    setFavorite: {
      reducer: (
        _state,
        _action: PayloadAction<{ itemId: number; favorite: boolean; saleEventId?: number }>,
      ) => {},
      prepare: (itemId: number, favorite: boolean, saleEventId?: number) => ({
        payload: { itemId, favorite, saleEventId },
      }),
    },
    setFavoriteSuccess: {
      reducer: (state, action: PayloadAction<IResponse.SetFavoritesResponseData>) => {
        const { item } = action.payload;

        if (item?.favorite === 0) {
          state.favoritesIds = state.favoritesIds.filter((id) => id !== item.itemId);
          // TODO: change favorites total count in dashboard state
        }

        const favorite = { favorite: item.favorite === 1, itemId: item.id };

        const existingItem = state.items[item.id];
        if (existingItem) {
          existingItem.favorite = favorite;
        }

        const existingSearchResultItemIndex = state.searchResults.findIndex(
          (_item) => _item.id === item.id,
        );

        if (existingSearchResultItemIndex > -1) {
          state.searchResults[existingSearchResultItemIndex].favorite = favorite;
        }

        const existingLatestVehicleIndex = state.latestVehicles.findIndex(
          (_item) => _item.id === item.id,
        );
        if (existingLatestVehicleIndex > -1) {
          state.latestVehicles[existingLatestVehicleIndex].favorite = favorite;
        }
      },
      prepare: (data: IResponse.SetFavoritesResponseData) => {
        return { payload: data };
      },
    },
    setFavoriteFailure: (_state, _action: PayloadAction<string>) => {},

    setFavoritesNote: {
      reducer: (_state, _action: PayloadAction<{ itemId: number; notes: string }>) => {},
      prepare: (itemId: number, notes: string) => ({ payload: { itemId, notes } }),
    },
    setFavoritesNoteSuccess: {
      reducer: (state, action: PayloadAction<{ itemId: number; notes: string }>) => {
        const { itemId, notes } = action.payload;
        const existing = state.items[itemId];
        if (existing) {
          const composed = { notes };
          state.items[itemId] = _.merge(existing, composed);
        }
      },
      prepare: (itemId: number, notes: string) => {
        return { payload: { itemId, notes } };
      },
    },
    setFavoritesNoteFailure: (_state, _action: PayloadAction<string>) => {},

    getSearchResults: {
      reducer: (_state, _action: PayloadAction<IRequest.ItemsRequestBody>) => {},
      prepare: (payload: IRequest.ItemsRequestBody) => ({ payload }),
    },
    getSearchResultsSuccess: {
      reducer: (state, action: PayloadAction<IResponse.SearchItemResponse[]>) => {
        const searchResults = action.payload;

        const formattedItems = searchResults.map((item) => composeItemFromSearchItemResponse(item));

        Array.prototype.push.apply(state.searchResults, formattedItems);
      },
      prepare: (payload: IResponse.SearchItemResponse[]) => {
        return { payload };
      },
    },
    getSearchResultsFailure: (_state, _action: PayloadAction<string>) => {},
    clearSearchResults: (state) => {
      state.searchResults = [];
    },

    removePrebidSuccess: {
      reducer: (state, action: PayloadAction<number>) => {
        const itemId = action.payload;
        delete state.items[itemId]?.prebid;
      },
      prepare: (itemId: number) => {
        return { payload: itemId };
      },
    },

    // Bidding
    setBiddings: {
      reducer: (state, action: PayloadAction<IItem.Bidding[]>) => {
        const itemsBidding = action.payload;
        // _logger.info('redux/item.setItemsBidding', { itemsBidding });
        itemsBidding.forEach((itemBidding: IItem.Bidding) => {
          const { itemId, bidding } = itemBidding;
          _logger.info('redux/item.setItemsBidding', { itemId, bidding });
          const existingItem = state.items[itemId];
          if (existingItem) {
            existingItem.bidding = _.merge(existingItem.bidding, bidding);
          }
        });
      },
      prepare: (payload: IItem.Bidding[]) => ({ payload }),
    },

    setBidding: {
      reducer: (state, action: PayloadAction<IItem.Bidding>) => {
        const { itemId, bidding } = action.payload;
        const item = state.items[itemId];
        if (item) {
          item.bidding = _.merge(item.bidding, bidding);
        }
        // _logger.info('redux/item.setItemsBidding', { itemsBidding });
      },
      prepare: (itemId: number, bidding: Partial<IBidding.Bidding>) => ({
        payload: { itemId, bidding },
      }),
    },

    // TODO
    // When ebid items is no longer in ES (marketplace/v2/items),
    // we have to update the state from ebid endpoints with this
    setEbidItems: {
      reducer: (state, action: PayloadAction<IEbid.ItemsMap>) => {
        const items = action.payload;
        const formattedItems: ItemSourceMap = {};
        Object.values(items).forEach((item) => {
          const itemId = +item.clientItemId;
          const composed = {
            id: itemId,
            name: item.name,
            stockNum: item.stockNum,
            description: item.description,
            saleEvent: {
              id: +item.eventId,
              name: item.eventName,
              lotNum: item.lotNum,
            },
            eventType: IAuction.AUCTION_TYPES.STATIC,
            isEbid: true,
            image: item.imgUrl,
            conditionType: item.conditionType,
            vehicle: {
              odometer: item.odometer,
              odoUnits: item.odoUnits,
            },
            yard: {
              city: item.city,
            },
          };
          // if not merged here, item's offer status disappear
          formattedItems[itemId] = _.merge(state.items[itemId], composed);
        });
        _logger.info('redux/item.setEbidItems', { formattedItems });
        Object.assign(state.items, { ...formattedItems });
      },
      prepare: (payload: IEbid.ItemsMap) => ({ payload }),
    },

    addItem: {
      reducer: (state, action: PayloadAction<IItem.Item>) => {
        const item = action.payload;
        state.items[item.id] = state.items[item.id] ? _.merge(state.items[item.id], item) : item;
      },
      prepare: (item: IItem.Item) => ({ payload: item }),
    },

    updateFavorite: {
      reducer: (state, action: PayloadAction<IItem.Favorite>) => {
        const { itemId } = action.payload;
        const item = state.items[itemId];
        if (item) {
          const favorite = {
            favorite: action.payload,
          };
          state.items[itemId] = _.merge(item, favorite);
        }
      },
      prepare: (payload: IItem.Favorite) => ({ payload }),
    },

    updatePrebid: {
      reducer: (state, action: PayloadAction<IItem.Prebid>) => {
        const { itemId } = action.payload;
        const item = state.items[itemId];
        if (item) {
          const prebid = {
            prebid: action.payload,
          };
          state.items[itemId] = _.merge(item, prebid);
        }
      },
      prepare: (payload: IItem.Prebid) => ({ payload }),
    },

    getEbidItems: {
      reducer: (_state, _action: PayloadAction<number[]>) => {},
      prepare: (payload: number[]) => ({ payload }),
    },
    getEbidItemsSuccess: {
      reducer: (state, action: PayloadAction<IItem.Flat.EbidItemFlat[]>) => {
        const items = action.payload;
        items?.forEach((item) => {
          const itemId = +item.id;
          const composed = composeItemFromFlatResponse(item, RESPONSE_TYPE.EBID);
          state.items[itemId] = _.merge(state.items[itemId], composed);
        });
      },
      prepare: (payload: IItem.Flat.EbidItemFlat[]) => ({ payload }),
    },
    getEbidItemsFailure: {
      reducer: () => {},
      prepare: (error: string) => ({ payload: error }),
    },

    getBidActivity: () => {},
    getBidActivitySuccess: {
      reducer: (state, action: PayloadAction<IItem.Flat.BidActivityFlat[]>) => {
        const items = action.payload;
        items.forEach((item) => {
          const itemId = +item.clientItemId;
          const composed = composeItemFromFlatResponse(item, RESPONSE_TYPE.BID_ACTIVITY);
          state.items[itemId] = _.merge(state.items[itemId], composed);
        });
      },
      prepare: (bids: IItem.Flat.BidActivityFlat[]) => ({ payload: bids }),
    },
    getBidActivityFailure: {
      reducer: () => {},
      prepare: (error: string) => ({ payload: error }),
    },

    setBidAmount: {
      reducer: (state, action: PayloadAction<{ itemId: number; amount: number }>) => {
        const { itemId, amount } = action.payload;
        const item = state.items[itemId];
        if (item) {
          item.bidAmount = amount;
        }
      },
      prepare: (itemId: number, amount: number) => ({ payload: { itemId, amount } }),
    },
  },
  extraReducers: {
    [userActions.logout.type]: () => INITIAL_STATE,
  },
});

export const itemReducer = itemSlice.reducer;

export const itemActions = itemSlice.actions;
