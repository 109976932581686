import { RootState } from '..';
import { IItem } from '../../models';

export const ItemSelectors = {
  items: (state: RootState) => state.item.items,

  ebidItems: (state: RootState) => {
    return Object.values(state.item.items).filter((item) => item.isEbid);
  },

  myEbidItems: (state: RootState) => {
    return Object.values(state.item.items).filter(
      (item: IItem.Item) => item.isEbid && item.bidding?.hasBids,
    );
  },

  unpaidPurchases: (state: RootState) => {
    return Object.values(state.item.items).filter(
      (item: IItem.Item) => item?.status === IItem.STATUS.SALEPEND && !!item.purchaseV2,
    );
  },

  paidPurchases: (state: RootState) => {
    return Object.values(state.item.items).filter(
      (item: IItem.Item) => item?.status === IItem.STATUS.SOLD && !!item.purchaseV2,
    );
  },

  offers: (state: RootState) => {
    return Object.values(state.item.items).filter(
      (item: IItem.Item) => !!item.offer && !!item.bidding?.isOfferWinner,
    );
  },

  recommendations: (state: RootState) => state.item.recommendations,

  latestVehicles: (state: RootState) => state.item.latestVehicles,

  recentUsedMotorcycleItemIds: (state: RootState) => state.item.recentUsedMotorcycleItemIds,

  recentIndustrialItemIds: (state: RootState) => state.item.recentIndustrialItemIds,

  recentSalvageItemIds: (state: RootState) => state.item.recentSalvageItemIds,

  searchResults: (state: RootState) => state.item.searchResults,

  favoritesAll: (state: RootState) => state.item.favoritesAll,

  favoritesIds: (state: RootState) => state.item.favoritesIds,

  ebidItemsInSendingState: (state: RootState) => {
    return Object.values(state.item.items).filter(
      (item) => item.isEbid && item.bidding?.sendingState?.isSending,
    );
  },

  ebidItemsWithinSendingDuration: (state: RootState) => {
    const ebidItemsInSendingState = ItemSelectors.ebidItemsInSendingState(state);

    return ebidItemsInSendingState.filter((item) => {
      const { hasExceededDuration } = item.bidding?.sendingState ?? {
        hasExceededDuration: false,
      };

      return !hasExceededDuration;
    });
  },
};
